import React, { useEffect } from "react";
import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { parse, stringify } from "qs";
import { useDispatch, useSelector } from "react-redux";
import { JsonLd } from "react-schemaorg";
import { LocalBusiness } from "schema-dts";

import getRegionComplianceRules from "api/requests/consumerApi/getRegionComplianceRules";
import getBrandPlacement, { Placement } from "api/requests/getBrandPlacement";
import { getDispensary } from "api/requests/getDispensary";
import { getDispensaryDeals } from "api/requests/getDispensaryDeals";
import getMenuItems, { GetMenuItemsReturn } from "api/requests/getMenuItems";
import { DISPENSARY_PAGES } from "constants/dispensary";
import { MenuProvider } from "context/MenuContext";
import { Deal } from "custom-types/Deals";
import { Dispensary, RetailType } from "custom-types/Dispensary";
import { MenuItem } from "custom-types/MenuItem";
import useDomain from "hooks/useDomain";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import {
  dispensaryJsonSchema,
  retailerMainMetaData,
} from "lib/dispensaryMetadata";
import {
  COMPLIANCE_RULESET_LOAD,
  ComplianceRuleset,
} from "redux/reducers/complianceRules";
import {
  getIsOrderingAllowed,
  getIsThirdPartyOrderingProhibited,
} from "redux/selectors/complianceRules";
import isDispensaryDualLicense from "utils/dispensary/isDispensaryDualLicense";
import { getDispensaryUrl } from "utils/dispensaryUrls";
import generateDispensaryRedirect from "utils/generateDispensaryRedirect";
import logError from "utils/logError";
import { getMenuTypeCookie } from "utils/menuTypeUtils";
import { getCountryCodeHeader } from "utils/requestContext";
import useTrackDispensaryPageViewSplitEvent from "utils/split/useTrackDispensaryPageViewSplitEvent";
import { trackUserHistory } from "utils/userHistory";

import DispensaryBreadcrumbs from "components/Dispensary/DispensaryBreadcrumbs";
import DispensaryHeader from "components/Dispensary/DispensaryHeader";
import DispensaryTabs from "components/Dispensary/DispensaryTabs";
import MenuNotAvailable from "components/Dispensary/MenuNotAvailable";
import NonCompliantMenu from "components/Dispensary/NonCompliantMenu";
import PaidTierMenu from "components/Dispensary/PaidTierMenu";
import UberEgress from "components/UberEgress";

import MetaTags from "../../components/MetaTags";

const NearbyStores = dynamic(
  () => import("components/Dispensary/NearbyStores"),
);
const AboutDispensary = dynamic(
  () => import("components/Dispensary/AboutDispensary"),
);
const ReviewConfirmationModal = dynamic(
  () => import("components/Dispensary/ReviewsHeader/ReviewConfirmationModal"),
);

type MainPageProps = {
  complianceRuleset: ComplianceRuleset;
  brandPlacement: Placement | null;
  deals: Deal[];
  dispensary: Dispensary;
  menuData: GetMenuItemsReturn;
  staffPicks: MenuItem[];
};

const MainPage: React.FC<MainPageProps> = ({
  complianceRuleset,
  brandPlacement,
  deals,
  dispensary,
  menuData,
  staffPicks,
}) => {
  const {
    id,
    city,
    coverPhotoUrl,
    featureTier,
    hasMenuItems,
    logoUrl,
    name,
    retailType,
    slug,
    state,
    uberEatsStoreUrl,
    tags,
  } = dispensary;
  const baseUrl = useDomain();
  const dispatch = useDispatch();

  const isInfoTier = featureTier === "info";
  const isDispensaryRetailType = retailType === RetailType.Dispensary;

  useTrackDispensaryPageViewSplitEvent({ page: "main", tier: featureTier });

  useEffect(() => {
    if (complianceRuleset) {
      dispatch({
        complianceRuleset,
        type: COMPLIANCE_RULESET_LOAD,
      });
    }
  }, []);

  useEffect(() => {
    trackUserHistory({
      resourceId: id,
      slug,
      type: "dispensary",
    });
  }, [id]);

  const router = useRouter();
  const countryCode = useDomainCountryCode();
  const thirdPartyOrderingProhibited = useSelector(
    getIsThirdPartyOrderingProhibited,
  );

  let isLeaflyOrderingAllowed = useSelector(getIsOrderingAllowed);
  // * Only "dispensary" types are restricted, since clinic and cbd-store types
  // * typically don't sell restricted items.
  if (!isDispensaryRetailType) {
    isLeaflyOrderingAllowed = true;
  }

  const showConfirmationModal = !!router.query?.show_confirmation_modal;
  const showFavoriteDispensaryMessage = !!router.query?.show_favorite_message;

  const ssoMenuRedirect = async () => {
    try {
      await router.push(
        getDispensaryUrl({
          countryCode,
          slug,
          tab: "menu",
        }),
      );
    } catch (err) {
      logError(
        "Error redirecting in ssoMenuRedirect on /dispensary-info/[slug]",
      );
    }
  };

  const ssoDispensaryRedirect = async () => {
    try {
      await router.push(
        getDispensaryUrl({
          countryCode,
          slug,
        }),
      );
    } catch (err) {
      logError(
        "Error redirecting in ssoDispensaryRedirect on /dispensary-info/[slug]",
      );
    }
  };

  const { description, image, title } = retailerMainMetaData(
    city,
    countryCode,
    coverPhotoUrl,
    logoUrl,
    name,
    retailType as RetailType,
    state,
  );

  const isDualLicense = isDispensaryDualLicense(tags);

  return (
    <MenuProvider
      isDualLicenseDispensary={isDualLicense}
      initialData={menuData}
      staffPicks={staffPicks}
    >
      <MetaTags
        canonicalParams={[]}
        description={description}
        image={image}
        title={title}
      />
      <DispensaryHeader dispensary={dispensary} isMainPage={true} />
      <JsonLd<LocalBusiness>
        item={dispensaryJsonSchema(
          dispensary,
          baseUrl,
          dispensary.country || countryCode,
        )}
      />
      {thirdPartyOrderingProhibited && isDispensaryRetailType && (
        <NonCompliantMenu
          dispensaryId={dispensary.id}
          dispensaryName={dispensary.name}
          dispensaryWebsite={dispensary.website}
          hideCTA={isInfoTier}
          thirdPartyOrderingProhibited={thirdPartyOrderingProhibited}
          isLeaflyOrderingAllowed={isLeaflyOrderingAllowed}
        />
      )}
      <DispensaryTabs
        activeTab={DISPENSARY_PAGES.main}
        dispensary={dispensary}
      />
      {uberEatsStoreUrl && (
        <UberEgress
          url={uberEatsStoreUrl}
          className="container mt-lg md:hidden"
          dispensaryId={dispensary.id}
        />
      )}
      <div className="flex flex-col gap-xxl">
        {!isInfoTier && hasMenuItems ? (
          <PaidTierMenu
            dispensary={dispensary}
            brandPlacement={brandPlacement}
            deals={deals}
          />
        ) : (
          <>
            <MenuNotAvailable
              dispensaryFeatureTier={dispensary.featureTier}
              dispensaryId={dispensary.id}
              dispensaryName={dispensary.name}
              dispensaryRetailType={dispensary.retailType}
            />
            {isInfoTier && (
              <NearbyStores
                dispensaryId={dispensary.id}
                locations={dispensary.locations}
                locationPaths={dispensary.locationPaths}
                className="container mt-xl md:mt-[40px] mb-lg"
              />
            )}
          </>
        )}
        <AboutDispensary dispensary={dispensary} />
        {showConfirmationModal && (
          <ReviewConfirmationModal
            dispensaryId={dispensary.id}
            favorite={showFavoriteDispensaryMessage}
            ssoMenuRedirect={ssoMenuRedirect}
            ssoDispensaryRedirect={ssoDispensaryRedirect}
          />
        )}
        <DispensaryBreadcrumbs dispensary={dispensary} />
      </div>
    </MenuProvider>
  );
};

// @ts-expect-error -- TODO: Fix return type to adhere to props shape -- https://leafly.atlassian.net/browse/NL-1564
export const getServerSideProps: GetServerSideProps<MainPageProps> = async (
  context,
) => {
  const { query, req, resolvedUrl } = context;
  const { slug, menu_type, ...remainingQuery } = query;

  const dispensary = await getDispensary(slug as string);

  const domainCountryCode = getCountryCodeHeader(req.headers);

  const redirect = generateDispensaryRedirect(
    domainCountryCode,
    resolvedUrl,
    query,
    dispensary,
  );

  if (redirect) {
    return { redirect };
  }

  const userMedRecPreference = menu_type
    ? Array.isArray(menu_type)
      ? String(menu_type)
      : menu_type
    : getMenuTypeCookie(req);
  const isDualLicenseDispensary = isDispensaryDualLicense(
    dispensary?.tags || [],
  );

  const menuItemsCall = getMenuItems(slug as string, {
    ...parse(stringify(remainingQuery)),
    enableNewFilters: true,
    menu_type: isDualLicenseDispensary ? userMedRecPreference : undefined,
  });

  const complianceCall = getRegionComplianceRules(
    dispensary?.state,
    dispensary?.country,
  );

  const brandPlacementCall = getBrandPlacement(
    (dispensary as Dispensary).slug,
    {
      menuType: isDualLicenseDispensary ? userMedRecPreference : undefined,
      strategies: ["lite-amplification", "menu-merchandising"],
    },
  );

  const dealsCall = getDispensaryDeals((dispensary as Dispensary).slug);

  const staffPicksCall = getMenuItems(slug as string, {
    is_staff_pick: true,
    menu_type: isDualLicenseDispensary ? userMedRecPreference : undefined,
  });

  const [
    menuData,
    complianceRuleset,
    brandPlacement,
    { data: deals },
    { menuItems: staffPicks },
  ] = await Promise.all([
    menuItemsCall,
    complianceCall,
    brandPlacementCall,
    dealsCall,
    staffPicksCall,
  ]);

  return {
    props: {
      brandPlacement,
      complianceRuleset,
      deals,
      dispensary,
      menuData,
      staffPicks,
    },
  };
};

export default MainPage;
